export const createSaleDto = {
  name: "",
  description: "",
  isActive: true,
  beginsAt: "",
  endsAt: "",
  discounts: []
};

Object.freeze(createSaleDto);
