<template>
  <div>
    <h2 class="mb-4">Новая распродажа</h2>

    <v-sheet class="pa-4" elevation="3">
      <sale-form :sale="sale" :loading="loading" @change-field="changeField" @submit="createSale" @clear="clear" />
    </v-sheet>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

import { editorForm } from "~/mixins/multi-editor/editor-form";

import { createSaleDto } from "~/libs/sales/create-sale-dto";
import { Sale } from "~/libs/sales/sale";

import SaleForm from "~/components/sales/form";

export default {
  mixins: [editorForm],
  data() {
    return {
      loading: false,

      sale: { ...createSaleDto }
    };
  },
  methods: {
    async createSale() {
      this.loading = true;

      try {
        await this.$axios.$post("discounts/sales", Sale.to(this.sale));

        this.clear();

        this.$snackbar.success("Распродажа создана");
      } catch (e) {
        const html = new ApiValidationError(e, Object.keys(this.sale)).toHtml();

        this.$snackbar.error("Не удалось создать распродажу: " + html);
      } finally {
        this.loading = false;
      }
    },

    clear() {
      this.sale = { ...createSaleDto };
    },
    changeField(payload) {
      this.commitChange(this.sale, payload);
    }
  },
  components: {
    SaleForm
  }
};
</script>
